<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Requested Products</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" :class="{ active: currentTab == 'REQUESTED_PRODUCT' }"
                  v-on:click="changeTab('REQUESTED_PRODUCT')" style="cursor: pointer">
                  <span>Requested Products</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" :class="{ active: currentTab == 'REJECTED_PRODUCT' }"
                  v-on:click="changeTab('REJECTED_PRODUCT')" style="cursor: pointer">
                  <span>Rejected Products</span></a>
              </li>
            </ul>
            <div v-show="currentTab == 'REQUESTED_PRODUCT'">
              <div class="row">
                <div class="col-lg-6 col-sm-6 form-group row">
                  <div class="has-search ml-3">
                    <!-- <span class="bx bx-search form-control-feedback"></span> -->
                    <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                    <input type="text" class="form-control" placeholder="Search " v-model="searchObj.keyword"
                      v-on:keyup.enter="getRequestedProductList" />
                  </div>
                  <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getRequestedProductList(0)">Search</a>
                  <a class="fill-btn btn-style cursor-pointer" v-on:click="requestedProductResetFilter"
                    v-show="searchObj.keyword">Reset</a>
                </div>
              </div>
              <div class="card border mb-0 radius-15">
                <div class="card-body p-0 pb-4">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                        aria-describedby="example_info">
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th class="sorting" id="id-name" v-on:click="
                              requestedProductSorting('name', 'id-name')
                              ">
                              User Name
                            </th>
                            <th>Product Front Image</th>
                            <th>Product EAN Image</th>
                            <th>Product Ingredient Image</th>
                            <th class="sorting" id="id-code" v-on:click="
                              requestedProductSorting('code', 'id-code')
                              ">
                              EAN Code
                            </th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody v-show="!requestedProductLoader">
                          <tr v-for="(data, index) in requestedProductlist" :key="index">
                            <td style="text-align: left">
                              {{
                                ($refs.requestedProductListPagination
                                  .currentPage -
                                  1) *
                                10 +
                                index +
                                1
                              }}
                            </td>
                            <td style="text-align: left">
                              {{ data.user ? data.user.name : "" }}
                            </td>
                            <td>
                              <img :src="data.image
                                  ? data.image
                                  : '/images/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.barcode_img
                                  ? data.barcode_img
                                  : '/img/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.ingredient_img
                                  ? data.ingredient_img
                                  : '/img/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td style="text-align: left">
                              {{ data.barcode_number }}
                            </td>
                            <td style="text-align: left">{{ data.status }}</td>
                            <td v-show="accessObject?.is_view == 1">
                              <router-link :to="'/view-requested/' + data.id">
                                <a class="fill-btn btn-style cursor-pointer">View</a>&nbsp;
                              </router-link>
                              <a class="fill-btn btn-style cursor-pointer" v-on:click="$router.push('/accept/' + data.id)"
                                v-show="accessObject?.is_edit == 1">Accept</a>&nbsp;
                              <a class="fill-btn btn-style cursor-pointer" v-on:click="showRejectPopup(data)"
                                v-show="accessObject?.is_delete == 1">Reject</a>&nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-0 mb-4">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination @page-change="requestedPageChange" @items-per-page-change="requestedProductsPerPageChange
                          " ref="requestedProductListPagination" />
                      </div>
                    </div>
                  </div>
                  <ErrorComponent @retry="getRequestedProductList()" ref="requestedProductErrorComponent" />
                </div>
              </div>
            </div>
            <div v-show="currentTab == 'REJECTED_PRODUCT'">
              <div class="row">
                <div class="col-lg-6 col-sm-6 form-group row">
                  <div class="has-search ml-3">
                    <!-- <span class="bx bx-search form-control-feedback"></span> -->
                    <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                    <input type="text" class="form-control" placeholder="Search " v-model="searchObj.keyword"
                      v-on:keyup.enter="getRejectedProductList" />
                  </div>
                  <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getRejectedProductList(0)">Search</a>
                  <a class="fill-btn btn-style cursor-pointer" v-on:click="rejectedProductResetFilter"
                    v-show="searchObj.keyword">Reset</a>
                </div>
              </div>
              <div class="card border mb-0 radius-15">
                <div class="card-body p-0 pb-4">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4">
                      <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                        aria-describedby="example_info">
                        <thead>
                          <tr role="row">
                            <th>Sr. No.</th>
                            <th>Product Front Image</th>
                            <th>Product EAN Image</th>
                            <th>Product Ingredient Image</th>
                            <th class="sorting" id="id-code" v-on:click="
                              rejectedProductSorting('code', 'id-code')
                              ">
                              EAN Code
                            </th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody v-show="!rejectedProductLoader">
                          <tr v-for="(data, index) in rejectedProductList" :key="index">
                            <td style="text-align: left">
                              {{
                                ($refs.rejectedProductListPagination
                                  .currentPage -
                                  1) *
                                10 +
                                index +
                                1
                              }}
                            </td>
                            <td>
                              <img :src="data.image
                                    ? data.image
                                    : '/images/placeholder.gif'
                                  " width="40" height="40" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.barcode_img
                                  ? data.barcode_img
                                  : '/images/placeholder.gif'
                                " width="40" height="40" style="border-radius: 100px" />
                            </td>
                            <td>
                              <img :src="data.ingredient_img
                                  ? data.ingredient_img
                                  : '/img/placeholder.gif'
                                " width="50" height="50" style="border-radius: 100px" />
                            </td>
                            <td style="text-align: left">
                              {{ data.barcode_number }}
                            </td>
                            <td style="text-align: left">
                              {{ data.product_status }}
                            </td>
                            <td>
                              <router-link :to="'/view-requested/' + data.id">
                                <a class="fill-btn btn-style cursor-pointer"
                                  v-show="accessObject?.is_view == 1">View</a>&nbsp;
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-0 mb-4">
                    <div class="col-lg-12 mt-5 text-center">
                      <div class="page-bottom-pagination text-right">
                        <Pagination @page-change="rejectedPageChange"
                          @items-per-page-change="rejectedProductsPerPageChange" ref="rejectedProductListPagination" />
                      </div>
                    </div>
                  </div>
                  <ErrorComponent @retry="getRejectedProductList()" ref="rejectedProductErrorComponent" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%" v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view requested products</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
export default {
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  name: "RequestedProduct",
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Requested Products"),

      type: this.$route.query.type,
      isShowAccessDeniedMessage: false,

      currentTab: "REQUESTED_PRODUCT",
      requestedProductLoader: true,
      rejectedProductLoader: true,
      requestedProductOffset: -1,
      rejectedProductOffset: -1,
      requestedPage: 10,
      rejectedPage: 10,
      searchObj: {
        keyword: "",
      },
      requestedProductlist: [],
      rejectedProductList: [],
      barcode: undefined,
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.changeTab("REQUESTED_PRODUCT");
  },
  methods: {
    changeTab(tab) {
      this.searchObj.keyword = "";
      this.currentTab = tab;
      if (tab == "REQUESTED_PRODUCT") {
        this.getRequestedProductList(0);
      } else {
        this.getRejectedProductList(0);
      }
    },
    requestedPageChange(requestedProductOffset) {
      this.getRequestedProductList(requestedProductOffset - 1);
    },
    requestedProductsPerPageChange() {
      this.requestedPage =
        this.$refs.requestedProductListPagination.requestedProductsPerPageChange;
      this.getRequestedProductList(0);
    },
    rejectedPageChange(rejectedProductOffset) {
      this.getRejectedProductList(rejectedProductOffset - 1);
    },
    rejectedProductsPerPageChange() {
      this.rejectedPage =
        this.$refs.rejectedProductListPagination.rejectedProductsPerPageChange;
      this.getRejectedProductList(0);
    },
    getRequestedProductList(page, orderBy, sortBy) {
      this.$refs.requestedProductErrorComponent.updateLoader(true);
      this.requestedProductlist = [];
      this.searchObj.requestedProductOffset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      var obj = {
        offset: this.requestedProductOffset,
        type: "PENDING",
        limit: this.requestedPage
      };
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      this.$api
        .getAPI({
          _action: "/requested/product/list",
          _body: obj,
        })
        .then((res) => {
          this.requestedProductLoader = false;
          this.requestedProductlist = res.list;
          this.requestedProductOffset = res.next_offset;
          if (page == 0) {
            if (this.$refs.requestedProductListPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.requestedProductListPagination.setTotalCount(
                  res.count
                );
              } else {
                this.$refs.requestedProductListPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.requestedProductErrorComponent) {
            this.$refs.requestedProductErrorComponent.setData(
              res,
              "No Requested product available"
            );
            this.$refs.requestedProductErrorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.requestedProductLoader = false;
          if (this.$refs.requestedProductErrorComponent) {
            this.$refs.requestedProductErrorComponent.setData(0, undefined, "");
            this.$refs.requestedProductErrorComponent.updateLoader(false);
          }
        });
    },
    getRejectedProductList(page, orderBy, sortBy) {
      this.$refs.rejectedProductErrorComponent.updateLoader(true);
      this.rejectedProductList = [];
      this.searchObj.requestedProductOffset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      var obj = {
        offset: this.rejectedProductOffset,
        type: "REJECTED",
        limit: this.rejectedPage
      };
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      this.$api
        .getAPI({
          _action: "/requested/product/list",
          _body: obj,
        })
        .then((res) => {
          this.rejectedProductLoader = false;
          this.rejectedProductList = res.list;
          this.rejectedProductOffset = res.next_offset;
          if (page == 0) {
            if (this.$refs.rejectedProductListPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.rejectedProductListPagination.setTotalCount(
                  res.count
                );
              } else {
                this.$refs.rejectedProductListPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.rejectedProductErrorComponent) {
            this.$refs.rejectedProductErrorComponent.setData(
              res,
              "No Rejected product available"
            );
          }
          this.$refs.rejectedProductErrorComponent.updateLoader(false);
        })
        .catch(() => {
          this.rejectedProductLoader = false;
          this.$refs.rejectedProductErrorComponent.updateLoader(false);
          if (this.$refs.rejectedProductErrorComponent) {
            this.$refs.rejectedProductErrorComponent.setData(0, undefined, "");
          }
        });
    },
    requestedProductSorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getRequestedProductList(0, obj.order_by, obj.sort_by);
    },
    requestedProductResetFilter() {
      this.searchObj.keyword = "";
      this.getRequestedProductList(0);
    },
    rejectedProductSorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getRejectedProductList(0, obj.order_by, obj.sort_by);
    },
    rejectedProductResetFilter() {
      this.searchObj.keyword = "";
      this.getRejectedProductList(0);
    },
    showRejectPopup(data) {
      this.$refs.deleteModal.showModal(
        "Reject Product",
        "Are you sure you want to reject " + data.barcode_number,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      var obj = {};
      if (detail.id) {
        obj.id = detail.id;
      }
      this.$api
        .postAPI({
          _action: "/product-status",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getRequestedProductList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>