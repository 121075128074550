<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row">
          <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/customer')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">Likes</h4>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <ul class="nav nav-pills">
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/edit-customer/' + this.id)"
              >
                <span>Basic Details</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/post-review/' + this.id)"
              >
                <span>Posts/Reviews</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                :class="{ active: currentTab == 'LIKE' }"
                v-on:click="changeTab('LIKE')"
              >
                <span>Likes</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/shared/' + this.id)"
              >
                <span>Shared Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/customer-requested/' + this.id)"
              >
                <span>Requested Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/activity-log/' + this.id)"
              >
                <span>Activity Log</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/post/' + this.id)"
              >
                <span>Post & Discussion</span></a
              >
            </li>
          </ul>
          <div class="row mt-3">
            <div class="col-lg-6 col-sm-6 form-group row">
              <div class="has-search ml-3">
                <i
                  class="fa fa-search"
                  style="position: absolute; margin: 10px"
                  aria-hidden="true"
                ></i>
                <input
                  type="text"
                  class="form-control"
                  v-on:keyup.enter="getList(0)"
                  placeholder="Search"
                  v-model="searchObj.keyword"
                />
              </div>
              <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)"
                >Search</a
              >
              <a
                class="fill-btn btn-style cursor-pointer"
                v-on:click="resetFilter"
                v-show="searchObj.keyword"
                >Reset</a
              >
            </div>
          </div>
          <div class="card border mb-0 radius-15">
            <div class="card-body p-0 pb-4">
              <div class="table-responsive">
                <div class="dataTables_wrapper dt-bootstrap4">
                  <table
                    class="table table-striped table-bordered dataTable"
                    style="width: 100%"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No.</th>
                        <th
                          class="sorting"
                          id="id-name"
                          v-on:click="sorting('name', 'id-name')"
                        >
                          Product Name
                        </th>
                      </tr>
                    </thead>
                    <tbody v-show="!loader">
                      <tr v-for="(data, index) in list" :key="index">
                        <td style="text-align: left">{{ index + 1 }}</td>
                        <td style="text-align: left">{{ data.product.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "LikePage",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "",
      loader: true,
      itemsPerPage: 10,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.getList();
    this.changeTab("LIKE");
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/all/like/product/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No like available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "LIKE") {
        this.$router.push("/like/" + this.id);
      }
    },
  },
};
</script>
