import {
    createWebHistory,
    createRouter
} from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import OTP from "@/views/Onboarding/otp.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import MyProfile from "@/views/Onboarding/my-profile.vue";
import Admin from "@/views/Admin/admin.vue";
import AddAdmin from "@/views/Admin/add-admin.vue";
import Brand from "@/views/Brand/brands.vue";
import AddBrand from "@/views/Brand/add-brand.vue";
import Topic from "@/views/Topic/topics.vue";
import AddTopic from "@/views/Topic/add-topic.vue";
import Ingredient from "@/views/Ingredient/ingredient.vue";
import AddIngredient from "@/views/Ingredient/add-ingredient.vue";
import MetaContent from "@/views/MetaContent/MetaContent.vue";
import AddMetaContent from "@/views/MetaContent/add-MetaContent.vue";
import Language from "@/views/Language/language.vue";
import Currency from "@/views/Currency/currency.vue";
import News from "@/views/News/news.vue";
import Category from "@/views/Category/category.vue";
import AddCategory from "@/views/Category/add-category.vue";
import SubCategory from "@/views/Category/sub-category.vue";
import SubSubCategory from "@/views/Category/sub-sub-category.vue";
import AddContent from "@/views/Content/edit-content.vue";
import Customer from "@/views/Customer/customer.vue";
import AllReview from "@/views/Manage Reviews/all-review.vue";
import AddCustomer from "@/views/Customer/add-customer.vue";
import PostReview from "@/views/Customer/post-review.vue";
import PostDiscussion from "@/views/Customer/post.vue";
import Like from "@/views/Customer/like.vue";
import SharedProduct from "@/views/Customer/shared-product.vue";
import CustomerRequestedProduct from "@/views/Customer/customer-requested-product.vue";
import ActivityLog from "@/views/Customer/activity-log.vue";
import Content from "@/views/Content/contents.vue";
import Product from "@/views/Products/products.vue";
import AddProduct from "@/views/Products/add-products.vue";
import AllProduct from "@/views/Products/all-products.vue";
import ProductAttribute from "@/views/Products/product_attribute.vue";
import ProductAttributeOption from "@/views/Products/attribute_option.vue";
import RequestedProduct from "@/views/Products/requested-products.vue";
import ViewRequestedProduct from "@/views/Products/view-requested-product.vue";
import ViewProduct from "@/views/Products/view-product.vue";
import AcceptProduct from "@/views/Products/accept-product.vue";
import RejectedProduct from "@/views/Products/rejected-products.vue";
import Dashboard from "@/views/Dashboard/dashboard.vue";
import Banner from "@/views/Banner/banner.vue";
import AddBanner from "@/views/Banner/add-banner.vue";
import Statistics from "@/views/Statistics/statistics.vue";
import Role from "@/views/Role/role.vue";
import AddRole from "@/views/Role/add-role.vue";
import ManageNotification from "@/views/Notification/manageNotification.vue";
import Notification from "@/views/SendNotification/notification.vue";
import ViewNotification from "@/views/SendNotification/view-notification.vue";
import SendNotification from "@/views/SendNotification/send-notification.vue";
import Menu from "@/components/menu.vue";
import MainView from "@/views/main.vue"
import AccessDenied from "@/views/Error/access-denied.vue"

const routes = [{
        path: "",
        redirect: Login,
    },
    {
        path: "/login",
        name: "LoginPage",
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/otp",
        name: "OTP",
        component: OTP,
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: '',
        name: "MainPage",
        component: MainView,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "/access-denied",
                name: "AccessDenied",
                component: AccessDenied,
            },
            // =============== Admin ===============
            {
                path: "/admin",
                name: "Admin",
                component: Admin
            },
            {
                path: "/add-admin",
                name: "Add Admin",
                component: AddAdmin
            },
            {
                path: "/edit-admin/:id",
                name: "Edit Admin",
                component: AddAdmin
            },
            //===========My profile========
            {
                path: "/my-profile",
                name: "MyProfile",
                component: MyProfile,
            },
            // =============== Brand ===============
            {
                path: "/brand",
                name: "Brand",
                component: Brand
            },
            {
                path: "/add-brand",
                name: "Add Brand",
                component: AddBrand
            },
            {
                path: "/edit-brand/:id/:code",
                name: "Edit Brand",
                component: AddBrand
            },

            // =============== Topic ===============
            {
                path: "/topic",
                name: "Topic",
                component: Topic
            },
            {
                path: "/add-topic",
                name: "Add Topic",
                component: AddTopic
            },
            {
                path: "/edit-topic/:id/:code",
                name: "Edit Topic",
                component: AddTopic
            },


            // =============== Ingredient ===============
            {
                path: "/ingredient",
                name: "Ingredient",
                component: Ingredient
            },

            // =============== Language ===============
            {
                path: "/language",
                name: "Language",
                component: Language
            },

            // =============== Language ===============
            {
                path: "/currency",
                name: "Currency",
                component: Currency
            },

            {
                path: "/newsletter",
                name: "News",
                component: News
            },

            //================Category================
            {
                path: "/category",
                name: "Category",
                component: Category
            },
            {
                path: "/add-category/:parent_id",
                name: "AddCategory",
                component: AddCategory
            },
            {
                path: "/edit-category/:id/:code/:parent_id",
                name: "Edit Category",
                component: AddCategory
            },
            {
                path: "/sub-category/:id",
                name: "SubCategory",
                component: SubCategory
            },
            {
                path: "/sub-sub-category/:id",
                name: "SubSubCategory",
                component: SubSubCategory
            },
            // =============== Content ===============
            {
                path: "/content",
                name: "Content",
                component: Content
            },
            {
                path: "/edit-content",
                name: "AddContent",
                component: AddContent
            },
            // =============== Customer ===============
            {
                path: "/customer",
                name: "Customer",
                component: Customer
            },
            {
                path: "/add-customer",
                name: "Add Customer",
                component: AddCustomer
            },
            {
                path: "/edit-customer/:id",
                name: "Edit Customer",
                component: AddCustomer
            },
            {
                path: "/post-review/:id",
                name: "Post Review",
                component: PostReview
            },
            {
                path: "/like/:id",
                name: "Like",
                component: Like
            },
            {
                path: "/shared/:id",
                name: "Shared Product",
                component: SharedProduct
            },
            {
                path: "/customer-requested/:id",
                name: "Customer Requested Product",
                component: CustomerRequestedProduct
            },
            {
                path: "/activity-log/:id",
                name: "Activity Log",
                component: ActivityLog
            },
            {
                path: "/post/:id",
                name: "PostDiscussion",
                component: PostDiscussion
            },
            // =============== Dashboard ===============
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard
            },
            // =============== Banner ===============
            {
                path: "/banner",
                name: "Banner",
                component: Banner
            },
            {
                path: "/add-banner",
                name: "Add Banner",
                component: AddBanner
            },
            {
                path: "/edit-banner/:id/:code",
                name: "Edit Banner",
                component: AddBanner
            },
            // =============== Statistics ===============
            {
                path: "/statistics",
                name: "Statistics",
                component: Statistics
            },
            // =============== Notification ===============
            {
                path: "/manage-notification",
                name: "Manage Notification",
                component: ManageNotification
            },
            {
                path: "/snd-notification",
                name: "Notification",
                component: Notification
            },
            {
                path: "/view-notification-history/:id",
                name: "View Notification",
                component: ViewNotification
            },
            {
                path: "/send-notification",
                name: "Send Notification",
                component: SendNotification
            },
            // =============== Product ===============
            {
                path: "/product",
                name: "Product",
                component: Product
            },
            {
                path: "/add-product",
                name: "Add Product",
                component: AddProduct
            },
            {
                path: "/edit-product/:id/:code",
                name: "Edit Products",
                component: AddProduct
            },
            {
                path: "/add-ingredient",
                name: "Add Ingredient",
                component: AddIngredient
            },
            {
                path: "/edit-ingredient/:id/:code",
                name: "Edit Ingredient",
                component: AddIngredient
            },

            {
                path: "/metaContent",
                name: "MetaContent",
                component: MetaContent
            },

            {
                path: "/add-metaContent",
                name: "Add MetaContent",
                component: AddMetaContent
            },
            {
                path: "/edit-metaContent/:id/:code",
                name: "Edit MetaContent",
                component: AddMetaContent
            },

            {
                path: "/product_attribute",
                name: "Product Attribute",
                component: ProductAttribute
            },

            {
                path: "/product_attribute/:type/:id",
                name: "Product Attribute Category",
                component: ProductAttribute
            },
            {
                path: "/option/:id",
                name: "Attribute Option",
                component: ProductAttributeOption
            },
            {
                path: "/all-products",
                name: "All Product",
                component: AllProduct
            },

            {
                path: "/requested",
                name: "Requested Product",
                component: RequestedProduct
            },
            {
                path: "/view-requested/:id",
                name: "View Requested Product",
                component: ViewRequestedProduct
            },
            {
                path: "/view-product/:id",
                name: "View Product",
                component: ViewProduct
            },
            {
                path: "/accept/:id",
                name: "Accept Product",
                component: AcceptProduct
            },
            {
                path: "/rejected-product",
                name: "Rejected Product",
                component: RejectedProduct
            },
            // =============== Role ===============
            {
                path: "/role",
                name: "Role",
                component: Role
            },
            {
                path: "/add-role",
                name: "Add Role",
                component: AddRole
            },
            {
                path: "/edit-role/:id",
                name: "Edit Role",
                component: AddRole
            },
            /*==============Manage Review========*/
            {
                path: "/all-review",
                name: "All Review",
                component: AllReview
            },
            {
                path: "/menu",
                name: "Menu",
                component: Menu
            },

        ]
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: {
                    nextUrl: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            if (localStorage.getItem('token') != null) {
                next({
                    path: '/admin',
                    params: {
                        nextUrl: to.fullPath
                    }
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

export default router;