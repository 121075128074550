<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/customer')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Customer</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <ul v-show="detail.id" class="nav nav-pills">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  :class="{ active: currentTab == 'BASIC_DETAILS' }"
                  v-on:click="changeTab('BASIC_DETAILS')"
                >
                  <span>Basic Details</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/post-review/' + this.id)"
                >
                  <span>Posts/Reviews</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/like/' + this.id)"
                >
                  <span>Likes</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/shared/' + this.id)"
                >
                  <span>Shared Products</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/customer-requested/' + this.id)"
                >
                  <span>Requested Products</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/activity-log/' + this.id)"
                >
                  <span>Activity Log</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  v-on:click="$router.push('/post/' + this.id)"
                >
                  <span>Post & Discussion</span></a
                >
              </li>
            </ul>
            <div class="row mt-3">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form
                      ref="customerForm"
                      @keydown.enter="$event.preventDefault()"
                      @submit="save()"
                    >
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Name"
                              v-model="detail.name"
                              rules="required:name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>User Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="user_name"
                              class="form-control"
                              placeholder="User Name"
                              v-model="detail.user_name"
                              rules="required:user name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="user_name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="detail.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                            <div
                              data-toggle="modal"
                              data-target="#changePassword"
                              style="
                                float: right;
                                cursor: pointer;
                                text-decoration: underline;
                              "
                              v-show="detail.id"
                              v-on:click="showChangePasswordPopup"
                            >
                              <a>Change Password</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile number</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              v-slot="{ field }"
                              v-model="detail.mobile_number"
                              rules=":contact number|phone"
                              :validateOnInput="true"
                            >
                              <PhoneCode
                                id="mobile"
                                placeholder="Mobile Number"
                                v-model:countryCode="detail.country_code"
                                v-model="detail.mobile_number"
                                v-model:dialCode="detail.dial_code"
                                v-bind="field"
                              />
                              <ErrorMessage name="phone" class="text-danger" />
                            </Field>
                            <!-- <Field type="text" name="mobile" placeholder="Mobile no" v-slot="{ field }" rules="required:phone|phone">
                              <PhoneCode id="mobile" placeholder="Mobile Number" v-model:countryCode="
                                detail.country_code
                              " v-model="detail.mobile_number" v-model:dialCode="detail.dial_code" v-bind="field" />
                            </Field>
                            <ErrorMessage name="mobile" class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Address</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="address"
                              class="form-control"
                              placeholder="Address"
                              v-model="detail.address"
                              rules=":address"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="address" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Password </label>
                            <span class="text-danger">*</span>
                            <Field
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              placeholder="Password"
                              rules="required:password|minLength:6"
                              :validateOnInput="true"
                              v-model="detail.password"
                            />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              "
                            >
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                            <ErrorMessage name="password" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <span class="text-danger">*</span>
                            <Field
                              :type="passwordFieldType2"
                              placeholder="Confirm Password"
                              name="name_ccpassword"
                              class="form-control"
                              rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true"
                            />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              "
                            >
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchPasswordVisibility"
                                ><img :src="images" width="20"
                              /></a>
                            </span>
                            <ErrorMessage name="name_ccpassword" class="text-danger" />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputRole">Country</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="name_country"
                            v-model="detail.country_id"
                            rules="required:country"
                          >
                            <option value="">Select Country</option>
                            <option
                              v-for="(data, index) in countryList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                          <ErrorMessage name="name_country" class="text-danger" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Instagram</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="instagram"
                              class="form-control"
                              placeholder="Instagram"
                              v-model="detail.instagram"
                              rules=":instagram"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="instagram" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Age</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="age"
                              class="form-control"
                              placeholder="Age"
                              v-model="detail.age"
                              rules=":age|numeric"
                              :validateOnInput="true"
                              @keypress="$helperService.allowOnlyNumericValue($event)"
                            />
                            <ErrorMessage name="age" class="text-danger" />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputRole">Hair type</label>
                          <!-- <span class="text-danger">*</span> -->
                          <Field
                            as="select"
                            class="form-control"
                            id="inputRole"
                            name="hair_type"
                            v-model="detail.hair_style"
                            rules=":hair type"
                          >
                            <option value="">Select Hair type</option>
                            <option value="STRAIGHT">Curly Hair</option>
                            <option value="WAVE">Kinky Hair</option>
                            <option value="CURLY">Straight Hair</option>
                            <option value="KINKY">Wavy Hair</option>
                          </Field>
                          <ErrorMessage name="hair_type" class="text-danger" />
                        </div>
                        <div class="col-6 mb-3">
                          <label>Skin tone</label>
                          <ColorSlider v-model="detail.skin_tone" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Alergic Ingredients</label>
                            <Field
                              type="text"
                              name="alergic_ingredients"
                              class="form-control"
                              placeholder="Alergic Ingredients"
                              v-model="detail.alergic_ingredients"
                              v-on:keyup.enter="saveAlergicIngredients()"
                            />
                          </div>
                          <div class="row ml-1">
                            <div
                              style="
                                border: 0px;
                                height: 30px;
                                background: lightgray;
                                border-radius: 5px;
                                margin: 3px;
                              "
                              v-for="(data, index) in ingredients"
                              :key="index"
                            >
                              <label style="padding-left: 5px">{{ data }}</label>
                              <img
                                src="/img/cross.svg"
                                style="
                                  margin-right: 5px;
                                  margin-left: 5px;
                                  cursor: pointer;
                                "
                                v-on:click="removeAlergicIngredients(index)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Favorite Ingredients</label>
                            <Field
                              type="text"
                              name="favorite_ingredients"
                              class="form-control"
                              placeholder="Favorite Ingredients"
                              v-model="detail.favorite_ingredients"
                              v-on:keyup.enter="saveFavouriteIngredients()"
                            />
                          </div>
                          <div class="row ml-1">
                            <div
                              style="
                                border: 0px;
                                height: 30px;
                                background: lightgray;
                                border-radius: 5px;
                                margin: 3px;
                              "
                              v-for="(data, index) in favouriteIngredients"
                              :key="index"
                            >
                              <label style="padding-left: 5px">{{ data }}</label>
                              <img
                                src="/img/cross.svg"
                                style="
                                  margin-right: 5px;
                                  margin-left: 5px;
                                  cursor: pointer;
                                "
                                v-on:click="removeFavouriteIngredients(index)"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <div class="form-group">
                            <label>Newsletter</label>
                            <div class="mr-3">
                              <input
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                v-model="detail.is_newsletter"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                          <div class="form-group">
                            <label>Is Verified</label>
                            <div class="mr-3">
                              <input
                                type="checkbox"
                                true-value="Y"
                                false-value="N"
                                v-model="detail.is_verified"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6" v-if="detail.is_verified == 'Y'">
                          <label for="inputexpert">Expert</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            class="form-control"
                            id="inputexpert"
                            name="expert"
                            v-model="detail.expert"
                            rules="required:expert"
                          >
                            <option value="">Select Expert</option>
                            <option value="Hair Expert">Hair Expert</option>
                            <option value="Lip Expert">Lip Expert</option>
                            <option value="Face Expert">Face Expert</option>
                            <option value="Skin Expert">Skin Expert</option>
                          </Field>
                          <ErrorMessage name="expert" class="text-danger" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            class="fil-btn px-4 ml-3"
                            id="cancel-button"
                            v-on:click="$router.go(-1)"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="password"
              rules="required:password|minLength:6"
              :validateOnInput="true"
            />
            <span style="position: absolute; right: 22px; top: 40px; z-index: 999">
              <a class="psw-icon cursor-pointer" v-on:click="switchVisibility"
                ><img :src="image" width="20"
              /></a>
            </span>
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType2"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true"
            />
            <span style="position: absolute; right: 20px; top: 40px; z-index: 999">
              <a class="psw-icon cursor-pointer" v-on:click="switchPasswordVisibility"
                ><img :src="image" width="20"
              /></a>
            </span>

            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="change-password-button"
            style="width: 20%; border-radius: 5px"
          >
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import ColorSlider from "@/components/ColorSlider";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "AddCustomer",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    ColorSlider,
    PhoneCode,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "",
      countryList: [],
      ingredients: [],
      favouriteIngredients: [],
      detail: {
        is_verified: "N",
        alergic_ingredients: "",
        favorite_ingredients: "",
        country_id: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      // alergic_ingredients: "",
      // favorite_ingredients: "",
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    } else {
      this.getCountryList();
    }
    this.changeTab("BASIC_DETAILS");
  },
  methods: {
    getCountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
          if (this.detail.country_id != null) {
            this.country_id = this.detail.country_id;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/customer/detail/admin/" + this.id,
        })
        .then((res) => {
          this.detail = res;
          if (res.alergic_ingredients) {
            this.ingredients = res.alergic_ingredients.split("|");
            res.alergic_ingredients = "";
          }
          if (res.favorite_ingredients) {
            this.favouriteIngredients = res.favorite_ingredients.split("|");
            res.favorite_ingredients = "";
          }
          this.getCountryList();
        })
        .catch(() => {});
    },
    saveAlergicIngredients() {
      if (this.detail.alergic_ingredients) {
        this.ingredients.push(this.detail.alergic_ingredients);
        this.detail.alergic_ingredients = "";
      } else {
        this.$notify({
          type: "error",
          text: "Please add alergic ingredients",
        });
      }
    },
    removeAlergicIngredients(index) {
      this.ingredients.splice(index, 1);
    },
    saveFavouriteIngredients() {
      if (this.detail.favorite_ingredients) {
        this.favouriteIngredients.push(this.detail.favorite_ingredients);
        this.detail.favorite_ingredients = "";
      } else {
        this.$notify({
          type: "error",
          text: "Please add favorite ingredients",
        });
      }
    },
    removeFavouriteIngredients(index) {
      this.favouriteIngredients.splice(index, 1);
    },
    save() {
      if (this.ingredients && this.ingredients.length > 0) {
        var alergic_ingredients = this.ingredients.toString();
        this.detail.alergic_ingredients = alergic_ingredients.replaceAll(",", "|");
      }
      if (this.favouriteIngredients && this.favouriteIngredients.length > 0) {
        var favorite_ingredients = this.favouriteIngredients.toString();
        this.detail.favorite_ingredients = favorite_ingredients.replaceAll(",", "|");
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/edit/customer",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/customer/signup",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.password = this.password;
      obj.id = this.detail.id;
      this.$api
        .putAPI({
          _action: "/change/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchPasswordVisibility() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
      this.images =
        this.images === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>
