<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row col-lg-12 col-sm-12 my-auto">
          <h4 class="mb-0 lg-bld">Dashboard</h4>
        </div>
      </div>
      <div
        class="page-content"
        v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
      >
        <div class="card-body py-0 px-0">
          <div class="card border mb-0 radius-15">
            <div
              class="card-body review-page"
              style="margin-top: 20px; margin-bottom: -1px"
            >
              <div class="row">
                <div class="col-lg-3 col-md-1 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div
                        class="card-body review-page"
                        v-on:click="editCustomer()"
                        style="cursor: pointer"
                      >
                        <div>
                          <p>Total Customer : {{ countObj.total_users }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div
                        class="card-body review-page"
                        v-on:click="editBrand()"
                        style="cursor: pointer"
                      >
                        <div>
                          <p>Total Brands :{{ countObj.total_brand }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div
                        class="card-body review-page"
                        v-on:click="editProduct()"
                        style="cursor: pointer"
                      >
                        <div>
                          <p>Total Products :{{ countObj.total_product }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                  <div class="card-body py-0 px-0">
                    <div class="card border mb-0 radius-15">
                      <div
                        class="card-body review-page"
                        v-on:click="editReview()"
                        style="cursor: pointer"
                      >
                        <div>
                          <p>Total Reviews :{{ countObj.total_reviews }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view Dashboard</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Dashboard"),
      customerAccessObject: this.$helperService.getAccessLevel("Manage Customers"),
      brandAccessObject: this.$helperService.getAccessLevel("Manage Brands"),
      productAccessObject: this.$helperService.getAccessLevel("Manage Products"),
      isShowAccessDeniedMessage: false,
      countObj: {},
      categoryList: [],
      detail: {
        country_id: "",
      },
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getCount();
  },
  methods: {
    getCount() {
      this.$api
        .getAPI({
          _action: "/dashboard/total/new-user",
        })
        .then((res) => {
          this.countObj = res;
        })
        .catch(() => {});
    },
    editCustomer() {
      if (this.accessObject?.is_edit == 1 && this.customerAccessObject.is_view == 1) {
        this.$router.push("/customer");
      }
    },
    editBrand() {
      if (this.accessObject?.is_edit == 1 && this.brandAccessObject.is_view == 1) {
        this.$router.push("/brand");
      }
    },
    editProduct() {
      if (this.accessObject?.is_edit == 1 && this.productAccessObject.is_view == 1) {
        this.$router.push("/product");
      }
    },
    editReview() {
      if (this.accessObject?.is_delete == 1 && this.productAccessObject.is_view == 1) {
        this.$router.push("/all-review");
      }
    },
  },
};
</script>
