<template>
  <div class="page-wrapper">
    <div class="page-content-wrapper">
      <div class="shadow-btm">
        <div class="row">
          <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/customer')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">Posts / Reviews</h4>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="card-body py-0 px-0">
          <ul class="nav nav-pills">
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/edit-customer/' + this.id)"
              >
                <span>Basic Details</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                :class="{ active: currentTab == 'POST_REVIEWS' }"
                v-on:click="changeTab('POST_REVIEWS')"
              >
                <span>Posts/Reviews</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/like/' + this.id)"
              >
                <span>Likes</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/shared/' + this.id)"
              >
                <span>Shared Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/customer-requested/' + this.id)"
              >
                <span>Requested Products</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/activity-log/' + this.id)"
              >
                <span>Activity Log</span></a
              >
            </li>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link"
                data-toggle="tab"
                v-on:click="$router.push('/post/' + this.id)"
              >
                <span>Post & Discussion</span></a
              >
            </li>
          </ul>
          <div class="row mt-3">
            <div class="col-lg-6 col-sm-6 form-group row">
              <div class="has-search ml-3">
                <i
                  class="fa fa-search"
                  style="position: absolute; margin: 10px"
                  aria-hidden="true"
                ></i>
                <input
                  type="text"
                  class="form-control"
                  v-on:keyup.enter="getList(0)"
                  placeholder="Search"
                  v-model="searchObj.keyword"
                />
              </div>
              <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)"
                >Search</a
              >
              <a
                class="fill-btn btn-style cursor-pointer"
                v-on:click="resetFilter"
                v-show="searchObj.keyword"
                >Reset</a
              >
            </div>
          </div>
          <div class="card border mb-0 radius-15">
            <ul class="nav nav-pills m-3">
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab1"
                  v-on:click="setStatus('ACTIVE')"
                  :class="{ active: currentTab1 == 'ACTIVE' }"
                >
                  <span>Active</span></a
                >
              </li>
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link"
                  data-toggle="tab1"
                  v-on:click="setStatus('DELETED')"
                  :class="{ active: currentTab1 == 'DELETED' }"
                >
                  <span>Deleted</span></a
                >
              </li>
            </ul>
            <div class="card-body p-0 pb-4">
              <div class="table-responsive">
                <div class="dataTables_wrapper dt-bootstrap4">
                  <table
                    class="table table-striped table-bordered dataTable"
                    style="width: 100%"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No.</th>
                        <th
                          class="sorting"
                          id="id-barcode"
                          v-on:click="sorting('barcode', 'id-barcode')"
                        >
                          EAN
                        </th>
                        <th
                          class="sorting"
                          id="id-name"
                          v-on:click="sorting('name', 'id-name')"
                        >
                          Product Name
                        </th>
                        <th
                          class="sorting"
                          id="id-rating"
                          v-on:click="sorting('rating', 'id-rating')"
                        >
                          Ratings
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-show="!loader">
                      <tr v-for="(data, index) in list" :key="index">
                        <td style="text-align: left">{{ index + 1 }}</td>
                        <td style="text-align: left">{{ data.product.barcode }}</td>
                        <td style="text-align: left">{{ data.product.name }}</td>
                        <td>
                          <star-rating
                            id="exper-detail"
                            :increment="0.5"
                            v-if="data.rating || data.rating == 0"
                            :rating="data.rating"
                            :show-rating="false"
                            :star-size="20"
                            :read-only="true"
                          ></star-rating>
                        </td>
                        <td>
                          <a
                            class="fill-btn btn-style cursor-pointer"
                            v-on:click="showViewReviewPopup(data)"
                            >View</a
                          >
                          <a
                            class="fill-btn btn-style cursor-pointer"
                            v-on:click="showDeletePopup(data)"
                            v-if="currentTab1 == 'ACTIVE'"
                            >Delete</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">View Review</h5>
        <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="reviewForm">
        <div class="modal-body">
          <div class="col-lg-12 col-sm-12">
            <label>Comment</label>
            <div
              class=""
              id="message-div"
              style="height: 200px; overflow-x: hidden; scrollbar-width: thin"
            >
              {{ reviewDetail.comment }}
              <star-rating
                id="exper-detail"
                style="padding-left: 160px"
                :increment="0.5"
                v-if="reviewDetail.rating || reviewDetail.rating == 0"
                :rating="reviewDetail.rating"
                :show-rating="false"
                :star-size="30"
                :read-only="true"
              ></star-rating>
            </div>
          </div>

          <!-- <div class="col-lg-12 col-sm-12">
            <div class="form-group">
              <label>Ratings</label>
              <span class="text-danger">*</span>
              <Field
                type="text"
                class="form-control"
                name="rating"
                placeholder="Ratings"
                rules="required:rating"
                :validateOnInput="true"
              />
              <ErrorMessage name="rating" class="text-danger" />
            </div>
          </div> -->
          <!-- <div class="col-lg-12 col-sm-12">
            <div class="form-group">
              <label>Review</label>
              <span class="text-danger">*</span>
              <Field
                type="text"
                class="form-control"
                name="review"
                placeholder="Review"
                rules="required:review"
                :validateOnInput="true"
              />
              <ErrorMessage name="review" class="text-danger" />
            </div>
          </div> -->
        </div>
        <div class="modal-footer pb-0">
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Close
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
  <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
</template>
<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
import StarRating from "vue-star-rating";
import TextModal from "@/components/TextModal";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "PostReview",
  components: {
    ErrorComponent,
    Pagination,
    TextModal,
    DeleteModal,
    StarRating,
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      currentTab: "",
      loader: true,
      itemsPerPage: 10,
      searchObj: {
        keyword: "",
        status: "ACTIVE",
      },
      offset: -1,
      list: [],
      reviewDetail: {},
      currentTab1: "ACTIVE",
    };
  },
  mounted() {
    this.getList();
    this.changeTab("POST_REVIEWS");
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    setStatus(status) {
      this.searchObj.status = status;
      this.currentTab1 = status;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/review/pagination/list/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No post/review available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showViewReviewPopup(data) {
      this.reviewDetail = data;
      this.$refs.textModal.showModal();
      // this.$refs.reviewForm.resetForm();
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Post/Review",
        "Are you sure you want to delete " + data.product_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/review/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "POST_REVIEWS") {
        this.$router.push("/post-review/" + this.id);
      }
    },
  },
};
</script>
