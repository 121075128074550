<template>
    <div>
        <div class="page-wrapper">
            <div class="page-content-wrapper">
                <div class="shadow-btm">
                    <div class="row col-lg-6 col-sm-6 my-auto">
                        <h4 class="mb-0 lg-bld">Manage Reviews</h4>
                    </div>
                </div>
                <div class="page-content" v-show="accessObject?.is_view==1 || !isShowAccessDeniedMessage">
                    <div class="card-body py-0 px-0">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 form-group row">
                                <div class="has-search ml-3">
                                    <i class="fa fa-search" style="position: absolute; margin: 10px"
                                        aria-hidden="true"></i>
                                    <input type="text" class="form-control" v-on:keyup.enter="getList(0)"
                                        placeholder="Search" v-model="searchObj.keyword" />
                                </div>
                                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)">Search</a>
                                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                                    v-show="searchObj.keyword">Reset</a>
                            </div>
                            <!-- <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                                <a v-show="accessObject?.is_add==1" class="fill-btn cursor-pointer"
                                    style="padding: 11px; margin-right: 16px"
                                    v-on:click="$router.push('/add-customer')">+ Add</a>
                            </div> -->
                        </div>
                        <div class="card border mb-0 radius-15">
                            <div class="card-body p-0 pb-4">
                                <div class="table-responsive">
                                    <div class="dataTables_wrapper dt-bootstrap4">
                                        <table class="table table-striped table-bordered dataTable" style="width: 100%"
                                            role="grid" aria-describedby="example_info">
                                            <thead>
                                                <tr role="row">
                                                    <th>Sr. No.</th>
                                                    <th class="sorting" id="id-name"
                                                        v-on:click="sorting('name', 'id-name')">
                                                        <div style="width: 150px">Customer Name</div>

                                                    </th>
                                                    <th class="sorting" id="id-barcode"
                                                        v-on:click="sorting('barcode', 'id-barcode')">
                                                        EAN
                                                    </th>
                                                    <th class="sorting" id="id-product_name"
                                                        v-on:click="sorting('product_name', 'id-product_name')">
                                                        Product Name
                                                    </th>
                                                    <th class="sorting" id="id-created_date"
                                                        v-on:click="sorting('ratings.created_at', 'id-created_date')">
                                                        <div style="width: 150px">Created Date</div>
                                                    </th>
                                                    <th class="sorting" id="id-rating"
                                                        v-on:click="sorting('rating', 'id-rating')">
                                                        Ratings
                                                    </th>
                                                    <th
                                                        v-show="accessObject?.is_view == 1 || accessObject?.is_delete == 1 ">
                                                        Action</th>
                                                </tr>
                                            </thead>
                                            <tbody v-show="!loader">
                                                <tr v-for="(data, index) in list" :key="index">
                                                    <td style="text-align: left;">
                                                        {{
                                                        ($refs.listPagination.currentPage - 1) * 10 +
                                                        index +
                                                        1
                                                        }}
                                                    </td>
                                                    <td style="text-align: left;">{{ data.customer.name }}</td>
                                                    <td style="text-align: left;">{{ data.product.barcode }}</td>
                                                    <td style="text-align: left;">{{ data.product.name }}</td>
                                                    <td style="text-align: left;">{{
                                                    $helperService.getFormatedDate(data.created_at) }}</td>
                                                    <td>
                                                        <star-rating id="exper-detail" :increment="0.5"
                                                            v-if="data.rating || data.rating == 0" :rating="data.rating"
                                                            :show-rating="false" :star-size="20" :read-only="true">
                                                        </star-rating>
                                                    </td>
                                                    <td>
                                                        <a class="fill-btn btn-style cursor-pointer"
                                                            v-show="accessObject?.is_view == 1 "
                                                            v-on:click="showViewReviewPopup(data)">View</a>
                                                        <a class="fill-btn btn-style cursor-pointer"
                                                            v-show="accessObject?.is_delete == 1 "
                                                            v-on:click="showDeletePopup(data)">Delete</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mx-0 mb-4">
                                    <div class="col-lg-12 mt-5 text-center">
                                        <div class="page-bottom-pagination text-right">
                                            <Pagination @page-change="pageChange"
                                                @items-per-page-change="itemsPerPageChange" ref="listPagination" />
                                        </div>
                                    </div>
                                </div>
                                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" style="margin-top: 20%"
                    v-show="accessObject?.is_view==0 || isShowAccessDeniedMessage">
                    <h3>You do not have access to view customers</h3>
                </div>
            </div>
            <TextModal ref="textModal">
                <div class="modal-header pt-0">
                    <h5 class="modal-title">View Review</h5>
                    <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Form ref="reviewForm">
                    <div class="modal-body">
                        <div class="col-lg-12 col-sm-12">
                            <label>Comment</label>
                            <div class="" id="message-div"
                                style="height:200px; overflow-x: hidden; scrollbar-width: thin; ">
                                {{reviewDetail.comment}}
                                <star-rating id="exper-detail" style="padding-left:160px" :increment="0.5"
                                    v-if="reviewDetail.rating || reviewDetail.rating == 0" :rating="reviewDetail.rating"
                                    :show-rating="false" :star-size="30" :read-only="true"></star-rating>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer pb-0">
                        <button type="button" class="fil-btn" id="cancel-button" v-on:click="$refs.textModal.closeModal"
                            style="width: 20%; border-radius: 5px">
                            Close
                        </button>
                    </div>
                </Form>
            </TextModal>
        </div>
        <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
    </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";
import StarRating from "vue-star-rating";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
export default {
    name: "AllReview",
    components: {
        ErrorComponent,
        DeleteModal,
        TextModal,
        Pagination,
        StarRating,
    },
    data() {
        return {
            accessObject: this.$helperService.getAccessLevel("Manage Reviews"),
            loader: true,
            isShowAccessDeniedMessage: false,

            offset: -1,
            list: [],
            reviewDetail: {},
            itemsPerPage: 10,searchObj: {
                keyword: "",
            },
        };
    },
    mounted() {
        if (!this.accessObject) {
            this.isShowAccessDeniedMessage = true;
            this.$router.push("/access-denied");
            return;
        }
        this.getList(0);
    },
    methods: {
        pageChange(offset) {
            this.getList(offset - 1);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
            this.getList(0);
        },
        getList(page, orderBy, sortBy) {
            this.$refs.errorComponent.updateLoader(true);
            this.list = [];
            this.searchObj.offset = page;
this.searchObj.limit = this.itemsPerPage;
            if (orderBy) {
                this.searchObj.order_by = orderBy;
            }
            if (sortBy) {
                this.searchObj.sort_by = sortBy;
            }
            this.$api
                .getAPI({
                    _action: "/all/review/list/pagination",
                    _body: this.searchObj,
                })
                .then((res) => {
                    this.loader = false;
                    this.list = res.list;
                    this.offset = res.next_offset;
                    if (page == 0) {
                        if (this.$refs.listPagination) {
                            if (res.count != null && res.count > 0) {
                                this.$refs.listPagination.setTotalCount(res.count);
                            } else {
                                this.$refs.listPagination.setTotalCount(0);
                            }
                        }
                    }
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(res, "No review available");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                })
                .catch(() => {
                    this.loader = false;
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(0, undefined, "");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                });
        },
        sorting(sortBy, id) {
            var obj = this.$helperService.sorting(sortBy, id);
            this.getList(0, obj.order_by, obj.sort_by);
        },
        resetFilter() {
            this.searchObj.keyword = "";
            this.getList(0);
        },
        showDeletePopup(data) {
            this.$refs.deleteModal.showModal(
                "Delete Review",
                "Are you sure you want to delete " + data.product.name,
                data
            );
        },
        showViewReviewPopup(data) {
            this.reviewDetail = data;
            console.log("this.reviewDetail", this.reviewDetail)
            this.$refs.textModal.showModal();
            // this.$refs.reviewForm.resetForm();
        },
        deleteRecord(dataObj) {
            var detail = new Proxy(dataObj, {});
            this.$api
                .deleteAPI({
                    _action: "/review/" + detail.id,
                })
                .then((res) => {
                    if (res) {
                        this.$refs.deleteModal.closeModal();
                        this.getList();
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                    }
                })
                .catch(() => {
                    this.$refs.deleteModal.removeLoader();
                });
        },
    },
};
</script>