<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Products</h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-md-6 d-flex mb-3">
                <div class="has-search">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    style="width: 200px"
                    v-model="searchObj.keyword"
                  />
                </div>
                <div class="mt-1 mr-2">
                  <a
                    class="fill-btn btn-style cursor-pointer ml-3 mt-2"
                    v-on:click="getList(0)"
                    >Search</a
                  >
                </div>
                <div class="mt-0 mr-2">
                  <h2>Total: {{ total_count }}</h2>
                </div>

                <div class="mt-1">
                  <a
                    class="fill-btn btn-style cursor-pointer mt-2"
                    v-on:click="resetFilter()"
                    v-show="searchObj.keyword"
                    >Reset</a
                  >
                </div>
              </div>
              <div
                class="col-md-6 text-right"
                v-show="accessObject?.is_view == 1 && accessObject?.is_add == 1"
              >
                <a
                  v-on:click="showPracticePopup()"
                  class="fill-btn btn-style cursor-pointer ml-3"
                  data-toggle="modal"
                  >Import</a
                >

                <a class="fill-btn cursor-pointer btn-style" v-on:click="exportData()"
                  >Export</a
                >

                <a
                  v-show="accessObject?.is_add == 1"
                  class="fill-btn cursor-pointer btn-style"
                  v-on:click="$router.push('/add-product')"
                  >+ Add</a
                >
              </div>

              <div class="row col-12">
                <div class="col-md-3 d-flex">
                  <select
                    class="form-control mr-3"
                    v-model="searchObj.categorie_id"
                    @change="getList(0)"
                  >
                    <option value="">Select Category</option>
                    <option
                      v-for="(data, index) in categoryList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <a
                    class="fill-btn cursor-pointer btn-style"
                    v-show="searchObj.categorie_id"
                    v-on:click="resetCategoryFilter()"
                    >Reset</a
                  >
                </div>
                <div class="col-md-3 d-flex">
                  <select
                    class="form-control mr-3"
                    v-model="searchObj.brand_id"
                    v-on:change="getList(0)"
                  >
                    <option value="">Select Brand</option>
                    <option
                      v-for="(data, index) in brandList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.title }}
                    </option>
                  </select>
                  <a
                    class="fill-btn cursor-pointer btn-style"
                    v-show="searchObj.brand_id"
                    v-on:click="resetBrandFilter()"
                    >Reset</a
                  >
                </div>
                <div class="col-md-3 d-flex">
                  <Multiselect
                    mode="single"
                    trackBy="executive_name"
                    label="name"
                    valueProp="id"
                    placeholder="Select Custom Column"
                    :options="executiveList"
                    searchable="true"
                    v-bind="field"
                  >
                    <template v-slot:option="{ option }">
                      <div class="col-12 row" style="padding: 0">
                        <div class="col-11">
                          <input
                            type="checkbox"
                            :name="'checkbox_' + option.value"
                            :id="option.value"
                            :value="option.value"
                            true-value="Y"
                            false-value="N"
                            v-model="option.is_select"
                            :class="option.is_select == 'N' ? 'a' : 'b'"
                            v-on:change="column($event, option.value, option.index)"
                          />
                          {{ option.name }}
                        </div>
                      </div>
                    </template>
                  </Multiselect>
                </div>
                <div class="col-md-3 d-flex justify-content-end">
                  <a
                    v-on:click="deleteAllPopup()"
                    class="fill-btn btn-style cursor-pointer ml-3"
                    data-toggle="modal"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card border mb-0 radius-15 mt-2">
            <div class="card-body p-0 pb-4">
              <div class="table-responsive">
                <div class="dataTables_wrapper dt-bootstrap4">
                  <table
                    class="table table-striped table-bordered dataTable"
                    style="width: 100%"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                      <tr role="row">
                        <th v-show="showObj.sr_no">Sr. No.</th>
                        <th
                          class="sorting"
                          id="id-name"
                          v-on:click="sorting('name', 'id-name')"
                          v-show="showObj.product_name"
                        >
                          Product Name
                        </th>
                        <th
                          class="sorting"
                          id="id-bname"
                          v-on:click="sorting('name', 'id-bname')"
                          v-show="showObj.brand_name"
                        >
                          Brand Name
                        </th>
                        <th
                          class="sorting"
                          id="id-cname"
                          v-on:click="sorting('name', 'id-cname')"
                          v-show="showObj.category_name"
                        >
                          Category Name
                        </th>
                        <th
                          class="sorting"
                          id="id-code"
                          v-on:click="sorting('code', 'id-code')"
                          v-show="showObj.ean_code"
                        >
                          EAN Code
                        </th>
                        <th
                          class="sorting"
                          id="id-image"
                          v-on:click="sorting('image', 'id-image')"
                          v-show="showObj.image_avaliable"
                        >
                          Image Avaliable ?
                        </th>

                        <th
                          class="sorting"
                          id="id-price"
                          v-on:click="sorting('price', 'id-price')"
                          v-show="showObj.price"
                        >
                          <div style="width: 90px">Price($)</div>
                        </th>

                        <th>Product URL</th>
                        <th v-show="accessObject?.is_delete == 1">
                          <input
                            type="checkbox"
                            id="deleteAll"
                            v-show="accessObject?.is_delete == 1"
                            v-on:click="selectAllData($event)"
                            true-value="1"
                            false-value="0"
                            class="mr-2"
                            v-model="is_select"
                            :class="is_select == '0' ? 'a' : 'b'"
                          />
                          Select
                        </th>
                        <th v-show="accessObject?.is_edit == 1">Action</th>
                      </tr>
                    </thead>
                    <tbody v-show="!loader">
                      <tr v-for="(data, index) in list" :key="index">
                        <td style="text-align: left" v-show="showObj.sr_no">
                          {{ data.unique_number }}
                        </td>
                        <td style="text-align: left" v-show="showObj.product_name">
                          {{ data.name }}
                        </td>
                        <td style="text-align: left" v-show="showObj.brand_name">
                          {{ data.brand ? data.brand.name : "-" }}
                        </td>
                        <td style="text-align: left" v-show="showObj.category_name">
                          {{ data.category ? data.category.name : "-" }}
                        </td>
                        <td style="text-align: left" v-show="showObj.ean_code">
                          {{ data.barcode }}
                        </td>
                        <td style="text-align: left" v-show="showObj.image_avaliable">
                          {{ data.image ? "Y" : "N" }}
                        </td>
                        <td style="text-align: left" v-show="showObj.price">
                          {{ data.price }}
                        </td>

                        <td>
                          <a
                            href="#"
                            @click="
                              open('https://www.grademybeauty.com/product/' + data.slug)
                            "
                            >https://www.grademybeauty.com/product/ {{ data.slug }}</a
                          >
                        </td>
                        <td v-show="accessObject?.is_delete == 1">
                          <input
                            type="checkbox"
                            :id="'delete_' + data.id"
                            v-show="accessObject?.is_delete == 1"
                            v-on:click="deleteData($event, data)"
                            v-model="data.is_select"
                            true-value="1"
                            false-value="0"
                            class="mr-2"
                            :class="data.is_select == '1' ? 'a' : 'b'"
                          />
                        </td>

                        <td v-show="accessObject?.is_edit == 1">
                          <a
                            class="fill-btn btn-style cursor-pointer"
                            v-on:click="showEditPopup(data)"
                            v-show="accessObject?.is_edit == 1"
                            >Edit</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-center"
        style="margin-top: 20%"
        v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
      >
        <h3>You do not have access to view product</h3>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteAllRecord()" ref="deleteModal" />

  <TextModal ref="textModal">
    <div class="modal-header">
      <h5 class="modal-title">Import Product</h5>
      <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-lg-12 col-sm-12 mb-3">
        <div class="text-center mb-4">
          <div class="form-group">
            <Field
              as="select"
              class="form-control"
              id="inputRole"
              name="name_import_type"
              v-model="import_type"
            >
              <option value="">Select Type</option>
              <option value="PRODUCT">Product</option>
              <option value="CURRENCY">Currency</option>
              <option value="ATTRIBUTE">Attribute</option>
            </Field>
          </div>

          <div class="form-group" v-if="import_type == 'PRODUCT'">
            <Field
              as="select"
              class="form-control"
              id="inputRole"
              name="name_language"
              v-model="importLangCode"
              rules="required:language"
            >
              <option value="">Select Language</option>
              <option
                v-for="(data, index) in languageList"
                :key="index"
                :value="data.code"
              >
                {{ data.title }} ({{ data.code }})
              </option>
            </Field>
            <ErrorMessage name="name_language" class="text-danger" />
          </div>

          <div class="input-group mb-3" v-if="import_type">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile04"
                v-on:change="onChange($event)"
              />
              <label
                class="custom-file-label"
                style="text-align: left"
                for="inputGroupFile04"
                >{{ docFile ? docFile.name : "Choose File" }}</label
              >
            </div>
          </div>
          <p v-if="import_type">
            <a href="" v-on:click="downloadTemplate">Download Template </a> file and fill
            with the items information.
          </p>
          <div id="msg"></div>
        </div>
      </div>
    </div>
    <div class="modal-footer" v-if="import_type">
      <button
        type="button"
        id="cancle-button"
        class="btn btn-secondary position-relative w-auto px-4"
        data-dismiss="modal"
        v-on:click="$refs.textModal.closeModal"
      >
        Close
      </button>
      <button
        v-on:click="uploadDocument()"
        id="document-button"
        type="button"
        class="btn btn-primary position-relative w-auto mt-0 mb-0 px-4"
      >
        Import
      </button>
    </div>
  </TextModal>

  <TextModal ref="editModal">
    <div class="modal-header">
      <h5 class="modal-title">Select Language</h5>
      <button type="button" class="close" v-on:click="$refs.editModal.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form @submit="editProduct">
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-sm-12 mb-3">
            <div class="form-group col-md-6">
              <label for="inputRole">Language</label>
              <span class="text-danger">*</span>
              <Field
                as="select"
                class="form-control"
                id="inputRole"
                name="name_language"
                v-model="editLangCode"
                rules="required:language"
              >
                <option value="">Select Language</option>
                <option
                  v-for="(data, index) in languageList"
                  :key="index"
                  :value="data.code"
                >
                  {{ data.title }} ({{ data.code }})
                </option>
              </Field>
              <ErrorMessage name="name_language" class="text-danger" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="fill-btn" id="save-button">Go</button>
        <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.editModal.closeModal"
        >
          Cancel
        </button>
      </div>
    </Form>
  </TextModal>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";
import Report from "../../Report";
import Multiselect from "@vueform/multiselect";
export default {
  name: "ProductPage",
  components: {
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    DeleteModal,
    Pagination,
    TextModal,
    Multiselect,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Products"),
      loader: true,
      isShowAccessDeniedMessage: false,
      searchObj: {
        keyword: "",
        categorie_id: "",
        brand_id: "",
      },
      itemsPerPage: 10,
      docFile: undefined,
      offset: -1,
      list: [],
      categoryList: [],
      brandList: [],
      deleteList: [],
      is_select: "0",
      import_type: "",
      executiveList: [
        {
          index: 0,
          name: "Sr. no.",
          value: "sr_no",
          is_select: "Y",
        },
        {
          index: 1,
          name: "Product Name",
          value: "product_name",
          is_select: "Y",
        },
        {
          index: 2,
          name: "Brand Name",
          value: "brand_name",
          is_select: "Y",
        },
        {
          index: 3,
          name: "Category Name",
          value: "category_name",
          is_select: "Y",
        },
        {
          index: 4,
          name: "EAN Code",
          value: "ean_code",
          is_select: "Y",
        },
        {
          index: 5,
          name: "Image Avaliable ?",
          value: "image_avaliable",
          is_select: "Y",
        },
        {
          index: 6,
          name: "Price",
          value: "price",
          is_select: "Y",
        },
      ],
      showObj: {
        sr_no: true,
        product_name: true,
        brand_name: true,
        category_name: true,
        ean_code: true,
        price: true,
        image_avaliable: true,
      },
      total_count: 0,
      edituuid: "",
      editLangCode: "",
      languageList: [],
      importLangCode: "EN",
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getList(0);
    this.getCategoryList(0);
    this.getBrandList(0);
  },
  methods: {
    open(url) {
      window.open(url, "_blank");
    },
    column(e, value, index) {
      if (e.target.checked) {
        if (value == "sr_no") {
          this.showObj.sr_no = true;
          this.executiveList[index].is_select = "Y";
        }
        if (value == "product_name") {
          this.showObj.product_name = true;
          this.executiveList[index].is_select = "Y";
        }
        if (value == "brand_name") {
          this.showObj.brand_name = true;
          this.executiveList[index].is_select = "Y";
        }
        if (value == "category_name") {
          this.showObj.category_name = true;
          this.executiveList[index].is_select = "Y";
        }
        if (value == "ean_code") {
          this.showObj.ean_code = true;
          this.executiveList[index].is_select = "Y";
        }
        if (value == "price") {
          this.showObj.price = true;
          this.executiveList[index].is_select = "Y";
        }

        if (value == "image_avaliable") {
          this.showObj.image_avaliable = true;
          this.executiveList[index].is_select = "Y";
        }
      } else {
        if (value == "sr_no") {
          this.showObj.sr_no = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "product_name") {
          this.showObj.product_name = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "brand_name") {
          this.showObj.brand_name = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "category_name") {
          this.showObj.category_name = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "ean_code") {
          this.showObj.ean_code = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "price") {
          this.showObj.price = false;
          this.executiveList[index].is_select = "N";
        }
        if (value == "image_avaliable") {
          this.showObj.image_avaliable = false;
          this.executiveList[index].is_select = "N";
        }
      }
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }

      this.searchObj.custom = this.showObj;
      this.$api
        .getAPI({
          _action: "product/list/admin",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.total_count = res.count;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No product available");
            this.$refs.errorComponent.updateLoader(false);
          }
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].is_select = "0";
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    resetCategoryFilter() {
      this.searchObj.keyword = "";
      this.searchObj.categorie_id = "";
      this.searchObj.brand_id = "";
      this.getList(0);
    },
    resetBrandFilter() {
      this.searchObj.keyword = "";
      this.searchObj.brand_id = "";
      this.getList(0);
    },
    showEditPopup(data) {
      this.getlanguageList();
      this.edituuid = data.uuid;
      this.$refs.editModal.showModal();
    },
    editProduct() {
      this.$refs.editModal.closeModal();
      this.$router.push("/edit-product/" + this.edituuid + "/" + this.editLangCode);
    },
    getlanguageList() {
      this.$api
        .getAPI({
          _action: "/language/all",
        })
        .then((res) => {
          this.languageList = res;
        })
        .catch(() => {});
    },
    deleteData(event, data) {
      if (event.target.checked) {
        this.deleteList.push(data.id);
      } else {
        for (var i = 0; i < this.deleteList.length; i++) {
          if (this.deleteList[i] == data.id) {
            this.deleteList.splice(i, 1);
          }
        }
      }

      setTimeout(() => {
        this.is_select = "1";
        for (var j = 0; j < this.list.length; j++) {
          if (this.list[j].is_select == "0") {
            this.is_select = "0";
            break;
          }
        }
      }, 1000);
    },
    selectAllData(event) {
      if (event.target.checked) {
        for (var i = 0; i < this.list.length; i++) {
          this.deleteList.push(this.list[i].id);
          this.list[i].is_select = "1";
        }
      } else {
        this.deleteList = [];
        for (var j = 0; j < this.list.length; j++) {
          this.list[j].is_select = "0";
        }
      }
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Product",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/product/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    deleteAllPopup() {
      this.$refs.deleteModal.showModal(
        "Delete Products",
        "Are you sure you want to delete " + this.deleteList.length,
        +" products"
      );
    },
    deleteAllRecord() {
      this.$api
        .postAPI({
          _action: "/delete/product-all",
          _body: { list: this.deleteList },
        })
        .then((res) => {
          if (res) {
            this.deleteList = [];
            this.is_select = "0";

            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    getCategoryList() {
      this.$api
        .getAPI({
          _action: "/category/list",
        })
        .then((res) => {
          this.categoryList = res.list;
        })
        .catch(() => {});
    },
    getBrandList() {
      this.$api
        .getAPI({
          _action: "/brand/list",
        })
        .then((res) => {
          this.brandList = res.list;
          if (this.searchObj.brand_id != null) {
            this.brand_id = this.searchObj.brand_id;
          }
        })
        .catch(() => {});
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    showPracticePopup() {
      this.getlanguageList();
      this.$refs.textModal.showModal();
    },
    exportData() {
      var sr_no = this.showObj.sr_no ? "Y" : "N";
      var product_name = this.showObj.product_name ? "Y" : "N";
      var category_name = this.showObj.category_name ? "Y" : "N";
      var brand_name = this.showObj.brand_name ? "Y" : "N";
      var ean_code = this.showObj.ean_code ? "Y" : "N";
      var image_avaliable = this.showObj.image_avaliable ? "Y" : "N";
      var price = this.showObj.price ? "Y" : "N";
      var url =
        "/product/export?sr_no=" +
        sr_no +
        "&product_name=" +
        product_name +
        "&category_name=" +
        category_name +
        "&brand_name=" +
        brand_name +
        "&ean_code=" +
        ean_code +
        "&image_avaliable=" +
        image_avaliable +
        "&price=" +
        price +
        "&categorie_id=" +
        this.searchObj.categorie_id +
        "&brand_id=" +
        this.searchObj.brand_id +
        "&keyword=" +
        this.searchObj.keyword;

      window.open(Report.REPORT_URL + "/api" + url);
    },
    uploadDocument() {
      if (!this.importLangCode) {
        this.$notify({
          type: "error",
          text: "Please select language",
        });
        return false;
      }

      if (!this.import_type) {
        this.$notify({
          type: "error",
          text: "Please select import type",
        });
        return false;
      }

      var url = "";
      if (this.import_type == "PRODUCT") {
        url = "/import/data";
      }
      if (this.import_type == "CURRENCY") {
        url = "/import/data/currency";
      }

      if (this.import_type == "ATTRIBUTE") {
        url = "/import/data/attribute";
      }

      if (this.docFile) {
        this.$api
          .uploadImageAPI({
            _action: url,
            _key: "file",
            _file: this.docFile,
            _body: { lang: this.importLangCode },
            _buttonId: "document-button",
          })
          .then((res) => {
            this.importLangCode = "EN";
            this.docFile = null;
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
          });
      } else {
        this.$refs.textModal.closeModal();
      }
    },
    downloadTemplate() {
      if (this.import_type == "PRODUCT") {
        var url = "sample-file.xlsx";
        window.open(Report.REPORT_URL + "/" + url);
      }

      if (this.import_type == "CURRENCY") {
        var currency_url = "currency-sample-file.xlsx";
        window.open(Report.REPORT_URL + "/" + currency_url);
      }

      if (this.import_type == "ATTRIBUTE") {
        var attribute_url = "attribute-sample-file.xlsx";
        window.open(Report.REPORT_URL + "/" + attribute_url);
      }
    },
  },
};
</script>
