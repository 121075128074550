<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Send Notifications</h4>
          </div>
        </div>
        <div class="page-content" v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 10px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(0)" placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter"
                  v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group"
                v-show="accessObject?.is_add == 1">
                <a class="fill-btn cursor-pointer" style="padding: 11px; margin-right: 16px"
                  v-on:click="this.$router.push('/send-notification')">
                  Send Notification
                </a>
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th class="sorting" id="id-created_at" v-on:click="sorting('created_at', 'id-created_at')">
                            Date & Time</th>
                          <th class="sorting" id="id-title" v-on:click="sorting('title', 'id-title')">
                            Title
                          </th>
                          <th class="sorting" id="id-text" v-on:click="sorting('text', 'id-text')">
                            Text
                          </th>
                          <th class="sorting" id="id-created_by" v-on:click="sorting('created_by', 'id-created_by')">
                            <div style="width: 90px">Send By</div></th>
                          <th v-show="accessObject?.is_view == 1">Action</th>
                        </tr>
                      </thead>

                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{
                            ($refs.listPagination.currentPage - 1) * 10 +
                            index +
                            1
                            }}
                          </td>
                          <td>
                            {{
                            $helperService.getFormatedDateAndTime(
                            data.created_at
                            )
                            }}
                          </td>
                          <td>{{ data.title }}</td>
                          <td>{{ data.text }}</td>
                          <td>{{data.created_by ? data.created_by.name : ''}}</td>
                          <td v-show="accessObject?.is_view == 1">
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="
                              $router.push('/view-notification-history/' + data.id)
                            " v-show="accessObject?.is_view == 1">View History</a>
                            <a class="fill-btn btn-style cursor-pointer" v-on:click="showDeletePopup(data)"
                              v-show="accessObject?.is_delete == 1">Delete</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view notification</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "NotificationPage",
  components: {
    ErrorComponent,
    Pagination,
    DeleteModal,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Send Notifications"),
      loader: false,
      isShowAccessDeniedMessage: false,
      itemsPerPage: 10,searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getList(0);
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      this.searchObj.offset = page;
this.searchObj.limit = this.itemsPerPage;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      var obj = {};
      if (this.searchObj.keyword) {
        obj.keyword = this.searchObj.keyword;
      }
      this.$api
        .getAPI({
          _action: "/admin/notification/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No notification available");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Notification",
        "Are you sure you want to delete " + data.title,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/notification/" + detail.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>

