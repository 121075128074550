<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Statistics</h4>
          </div>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-6 py-2">
                      <h2 class="mb-0 font-24">Total Customers</h2>
                    </div>
                    <div class="col-md-6 text-right">
                      <div
                        class="dropdown keep-inside-clicks-open d-inline-block dropleft chart-setting"
                      >
                        <a
                          class="font-30"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-gear" style="font-size: 24px"></i
                        ></a>
                        <ul class="dropdown-menu px-2" style="max-width: 350px">
                          <p class="pt-1">
                            Past
                            <input
                              type="number"
                              v-model="user_past"
                              v-on:change="getUser()"
                              style="width: 45px"
                              class="mx-2"
                            />
                            days/weeks/months
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card border mb-0 radius-15">
                    <div class="">
                      <ul class="nav nav-pills mb-3">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="user_tab === 'DAY' ? 'active' : ''"
                            v-on:click="getUser('DAY')"
                            style="cursor: pointer"
                          >
                            <span>Day</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: user_tab == 'WEEK' }"
                            v-on:click="getUser('WEEK')"
                            style="cursor: pointer"
                          >
                            <span>Week</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: user_tab == 'MONTH' }"
                            v-on:click="getUser('MONTH')"
                            style="cursor: pointer"
                          >
                            <span>Month</span></a
                          >
                        </li>
                      </ul>
                      <div v-if="isCustomerLoader" class="loader">
                        <img
                          src="/img/loader.gif"
                          alt="Loading.."
                          width="50"
                          height="50"
                        />
                      </div>
                      <div class="chartWrapper">
                        <div class="chartAreaWrapper">
                          <canvas
                            id="myChart1"
                            width="400"
                            height="150"
                            class="mt-3 ml-3"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pl-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-6 py-2">
                      <h2 class="mb-0 font-24">Total Products</h2>
                    </div>
                    <div class="col-md-6 text-right">
                      <div
                        class="dropdown keep-inside-clicks-open d-inline-block dropleft chart-setting"
                      >
                        <a
                          class="font-30"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-gear" style="font-size: 24px"></i
                        ></a>
                        <ul class="dropdown-menu px-2" style="max-width: 350px">
                          <p class="pt-1">
                            Past
                            <input
                              type="number"
                              v-model="product_past"
                              v-on:change="getProduct()"
                              style="width: 45px"
                              class="mx-2"
                            />
                            days/weeks/months
                          </p>
                          <!-- <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="ShowLegend">
                            <label class="form-check-label" for="ShowLegend">
                              Show Legend
                            </label>
                          </div> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card border mb-0 radius-15">
                    <div class="">
                      <ul class="nav nav-pills mb-3">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: product_tab == 'DAY' }"
                            v-on:click="getProduct('DAY')"
                            style="cursor: pointer"
                          >
                            <span>Day</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: product_tab == 'WEEK' }"
                            v-on:click="getProduct('WEEK')"
                            style="cursor: pointer"
                          >
                            <span>Week</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: product_tab == 'MONTH' }"
                            v-on:click="getProduct('MONTH')"
                            style="cursor: pointer"
                          >
                            <span>Month</span></a
                          >
                        </li>
                      </ul>
                      <div v-if="isProductLoader" class="loader">
                        <img
                          src="/img/loader.gif"
                          alt="Loading.."
                          width="50"
                          height="50"
                        />
                      </div>
                      <div class="chartWrapper">
                        <div class="chartAreaWrapper">
                          <canvas
                            id="myChart2"
                            width="400"
                            height="150"
                            class="mt-3 ml-3"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-6 py-2">
                      <h2 class="mb-0 font-24">Total Brands</h2>
                    </div>
                    <div class="col-md-6 text-right">
                      <div
                        class="dropdown keep-inside-clicks-open d-inline-block dropleft chart-setting"
                      >
                        <a
                          class="font-30"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-gear" style="font-size: 24px"></i
                        ></a>
                        <ul class="dropdown-menu px-2" style="max-width: 350px">
                          <p class="pt-1">
                            Past
                            <input
                              type="number"
                              v-model="brand_past"
                              v-on:change="getBrand()"
                              style="width: 45px"
                              class="mx-2"
                            />
                            days/weeks/months
                          </p>
                          <!-- <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="ShowLegend">
                            <label class="form-check-label" for="ShowLegend">
                              Show Legend
                            </label>
                          </div> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card border mb-0 radius-15">
                    <div class="">
                      <ul class="nav nav-pills mb-3">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: brand_tab == 'DAY' }"
                            v-on:click="getBrand('DAY')"
                            style="cursor: pointer"
                          >
                            <span>Day</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: brand_tab == 'WEEK' }"
                            v-on:click="getBrand('WEEK')"
                            style="cursor: pointer"
                          >
                            <span>Week</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: brand_tab == 'MONTH' }"
                            v-on:click="getBrand('MONTH')"
                            style="cursor: pointer"
                          >
                            <span>Month</span></a
                          >
                        </li>
                      </ul>
                      <div v-if="isBrandLoader" class="loader">
                        <img
                          src="/img/loader.gif"
                          alt="Loading.."
                          width="50"
                          height="50"
                        />
                      </div>
                      <div class="chartWrapper">
                        <div class="chartAreaWrapper">
                          <canvas
                            id="myChart3"
                            width="400"
                            height="150"
                            class="mt-3 ml-3"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3 pl-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-md-6 py-2">
                      <h2 class="mb-0 font-24">Total Reviews</h2>
                    </div>
                    <div class="col-md-6 text-right">
                      <div
                        class="dropdown keep-inside-clicks-open d-inline-block dropleft chart-setting"
                      >
                        <a
                          class="font-30"
                          href="javascript:void(0);"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-gear" style="font-size: 24px"></i
                        ></a>
                        <ul class="dropdown-menu px-2" style="max-width: 350px">
                          <p class="pt-1">
                            Past
                            <input
                              type="number"
                              v-model="review_past"
                              v-on:change="getReview()"
                              style="width: 45px"
                              class="mx-2"
                            />
                            days/weeks/months
                          </p>
                          <!-- <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="ShowLegend">
                            <label class="form-check-label" for="ShowLegend">
                              Show Legend
                            </label>
                          </div> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card border mb-0 radius-15">
                    <div class="">
                      <ul class="nav nav-pills mb-3">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: review_tab == 'DAY' }"
                            v-on:click="getReview('DAY')"
                            style="cursor: pointer"
                          >
                            <span>Day</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: review_tab == 'WEEK' }"
                            v-on:click="getReview('WEEK')"
                            style="cursor: pointer"
                          >
                            <span>Week</span></a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            :class="{ active: review_tab == 'MONTH' }"
                            v-on:click="getReview('MONTH')"
                            style="cursor: pointer"
                          >
                            <span>Month</span></a
                          >
                        </li>
                      </ul>
                      <div v-if="isReviewLoader" class="loader">
                        <img
                          src="/img/loader.gif"
                          alt="Loading.."
                          width="50"
                          height="50"
                        />
                      </div>
                      <div class="chartWrapper">
                        <div class="chartAreaWrapper">
                          <canvas
                            id="myChart4"
                            width="400"
                            height="150"
                            class="mt-3 ml-3"
                          >
                          </canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body py-0 px-0 mt-3">
              <div class="card border mb-0 radius-15 py-3">
                <div class="row col-12">
                  <div id="main" class="div-table col-md-4">
                    <div class="row align-items-center" style="">
                      <h6 class="col-md-10">Best Brands</h6>
                      <a
                        class="mb-2 col-md-2"
                        style="cursor: pointer"
                        v-on:click="showAddStatsPopup('brand')"
                      >
                        <i class="fa fa-download col-md-1"></i>
                      </a>
                      <div class="form-group col-md-12">
                        <select
                          class="form-control"
                          id="inputRole"
                          name="name_role"
                          v-model="country_id"
                          v-on:change="getTopBrandList(0)"
                        >
                          <option value="">Country</option>
                          <option
                            v-for="(data, index) in countryList"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="table-responsive"
                      style="
                        border-left: thin solid;
                        border-right: thin solid;
                        border-top: thin solid;
                        border-bottom: thin solid;
                      "
                    >
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="p-2 text-left">Sr. No.</th>
                            <th class="p-2 text-left">Brand Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in list" :key="index">
                            <td class="p-2 text-left">{{ index + 1 }}</td>
                            <td class="p-2 text-left">{{ data.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <ErrorComponent @retry="getTopBrandList(0)" ref="errorComponent" /> -->
                    </div>
                  </div>
                  <div id="main" class="div-table col-md-4">
                    <div class="row align-items-center" style="">
                      <h6 class="col-md-10">Best Products</h6>
                      <a class="mb-2 col-md-2" v-on:click="showAddStatsPopup('product')">
                        <i class="fa fa-download"></i>
                      </a>
                      <div class="form-group col-md-12">
                        <select
                          class="form-control"
                          id="inputRole"
                          name="name_role"
                          v-model="detail.product_country_id"
                          v-on:change="getTopProductList()"
                        >
                          <option value="">Country</option>
                          <option
                            v-for="(data, index) in countryList"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="table-responsive"
                      style="
                        border-left: thin solid;
                        border-right: thin solid;
                        border-top: thin solid;
                        border-bottom: thin solid;
                      "
                    >
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="p-2 text-left">Sr. No.</th>
                            <th class="p-2 text-left">Product Name</th>
                            <th class="p-2 text-left">Brand Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in productList" :key="index">
                            <td class="p-2 text-left">{{ index + 1 }}</td>
                            <td class="p-2 text-left">{{ data.name }}</td>
                            <td class="p-2 text-left">
                              {{ data.brand && data.brand.name ? data.brand.name : "" }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="main" class="div-table col-md-4">
                    <div class="row align-items-center" style="">
                      <h6 class="col-md-10">Best Shared Products</h6>
                      <a
                        class="mb-2 col-md-2 text-right"
                        style="cursor: pointer"
                        v-on:click="showAddStatsPopup('shared-product')"
                      >
                        <i class="fa fa-download"></i>
                      </a>
                      <div class="form-group col-md-12">
                        <select
                          class="form-control"
                          id="inputRole"
                          v-model="detail.shared_product_country_id"
                          v-on:change="getTopSharedProductList()"
                          name="name_role"
                        >
                          <option value="">Country</option>
                          <option
                            v-for="(data, index) in countryList"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="table-responsive"
                      style="
                        border-left: thin solid;
                        border-right: thin solid;
                        border-top: thin solid;
                        border-bottom: thin solid;
                      "
                    >
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="p-2 text-left">Sr. No.</th>
                            <th class="p-2 text-left">Product Name</th>
                            <th class="p-2 text-left">Brand Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in sharedProductList" :key="index">
                            <td class="text-left">{{ index + 1 }}</td>
                            <td class="text-left">
                              {{
                                data.product && data.product.name ? data.product.name : ""
                              }}
                            </td>
                            <td class="text-left">
                              {{ data.brand && data.brand.name ? data.brand.name : "" }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TextModal ref="textModal">
            <div class="modal-header pt-0">
              <h5 class="modal-title">Export</h5>
              <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Form ref="brandForm" @submit="downloadFile()">
              <div class="modal-body">
                <div class="form-group col-md-12">
                  <label for="inputRole">Parameter</label>
                  <span class="text-danger">*</span>
                  <Field
                    as="select"
                    class="form-control"
                    id="inputRole"
                    name="parameter"
                    v-model="detail.parameter"
                    rules="required:parameter"
                  >
                    <option value="">Select Parameter</option>
                    <option value="DAILY">Daily</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="YEARLY">Yearly</option>
                  </Field>
                  <ErrorMessage name="parameter" class="text-danger" />
                </div>
                <div class="col-12 row" v-if="detail.parameter == 'DAILY'">
                  <div class="col-6 form-group">
                    <label>Form Date</label>
                    <datepicker
                      v-model="detail.from_date"
                      class="form-control"
                      inputFormat="yyyy-MM-dd"
                      placeholder="From Date"
                      :clearable="false"
                      style="background-color: white !important"
                    />
                  </div>
                  <div class="col-6 form-group">
                    <label>To Date</label>
                    <datepicker
                      v-model="detail.to_date"
                      class="form-control"
                      inputFormat="yyyy-MM-dd"
                      placeholder="To Date"
                      :clearable="false"
                      :lowerLimit="detail.from_date"
                      style="background-color: white !important"
                    />
                  </div>
                </div>
                <div class="row ml-1">
                  <div class="col-6 form-group" v-if="detail.parameter == 'MONTHLY'">
                    <label>Select Month</label>
                    <datepicker
                      v-model="detail.month"
                      class="form-control"
                      inputFormat="MMM"
                      startingView="month"
                      placeholder="Select Month"
                      minimumView="month"
                      :clearable="false"
                      rules="required:month"
                      style="background-color: white !important"
                    />
                    <ErrorMessage name="month" class="text-danger" />
                  </div>
                  <div
                    class="form-group"
                    :class="detail.parameter == 'MONTHLY' ? 'col-6' : 'col-12'"
                    v-if="detail.parameter == 'MONTHLY' || detail.parameter == 'YEARLY'"
                  >
                    <label>Select Year</label>
                    <datepicker
                      v-model="detail.year"
                      class="form-control"
                      inputFormat="yyyy"
                      placeholder="Select Year"
                      startingView="year"
                      minimumView="year"
                      :clearable="false"
                      rules="required:year"
                      style="background-color: white !important"
                    />
                    <ErrorMessage name="year" class="text-danger" />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="inputRole">Country</label>
                  <span class="text-danger">*</span>
                  <Field
                    as="select"
                    class="form-control"
                    id="inputRole"
                    name="name_country"
                    v-model="detail.country_id"
                    rules="required:country"
                  >
                    <option value="">Select Country</option>
                    <option
                      v-for="(data, index) in countryList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="name_country" class="text-danger" />
                </div>
              </div>
              <div class="modal-footer pb-0">
                <div class="col-lg-12 col-sm-12 text-center mt-2">
                  <button id="report-button" class="fill-btn px-4 ml-3">
                    Generate Report
                  </button>
                </div>
              </div>
            </Form>
          </TextModal>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view statistics</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
// import ErrorComponent from "@/components/ErrorComponent";
import datepicker from "vue3-datepicker";
import moment from "moment";
import Report from "../../Report";
export default {
  name: "StatisticsPage",
  components: {
    TextModal,
    // ErrorComponent,
    Form,
    Field,
    ErrorMessage,
    datepicker,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Statistics"),
      isShowAccessDeniedMessage: false,
      loader: false,
      isCustomerLoader: false,
      isProductLoader: false,
      isBrandLoader: false,
      isReviewLoader: false,
      from_date: new Date(),
      to_date: new Date(),
      parameter: "",
      country_id: "",
      product_country_id: "",
      shared_product_country_id: "",
      past: "",
      user_past: 2,
      product_past: 2,
      brand_past: 2,
      review_past: 2,
      user_type: "",
      product_type: "",
      brand_type: "",
      review_type: "",
      myChart: undefined,
      productChart: undefined,
      brandChart: undefined,
      reviewChart: undefined,
      type: "",
      user_tab: "DAY",
      product_tab: "DAY",
      brand_tab: "DAY",
      review_tab: "DAY",
      countryList: [],
      list: [],
      productList: [],
      sharedProductList: [],
      user: [],
      product: [],
      brand: [],
      review: [],
      total_count: [],
      detail: {
        parameter: "",
        past: "",
        country_id: "",
        product_country_id: "",
        shared_product_country_id: "",
        from_date: new Date(),
        to_date: new Date(),
        month: null,
        year: null,
      },
    };
  },
  mounted() {
    if (!this.accessObject) {
      this.isShowAccessDeniedMessage = true;
      this.$router.push("/access-denied");
      return;
    }
    this.getUser("DAY");
    this.getProduct("DAY");
    this.getBrand("DAY");
    this.getReview("DAY");
    this.getCountryList();
    if (this.country_id) {
      this.getTopBrandList();
    }
    if (this.product_country_id) {
      this.getTopProductList();
    }
    if (this.shared_product_country_id) {
      this.getTopSharedProductList();
    }
  },
  methods: {
    getUser(type) {
      this.isCustomerLoader = true;
      this.user = "";
      var obj = {
        past: this.user_past,
      };
      if (type) {
        obj.type = type;
        this.user_type = type;
      } else {
        obj.type = this.user_type;
      }
      this.$api
        .getAPI({
          _action: "graph/data/user",
          _body: obj,
        })
        .then((res) => {
          (this.user = res.result.list), (this.isCustomerLoader = false);
          this.getUserChart();
        })
        .catch(() => {
          this.isCustomerLoader = false;
        });
    },
    getUserChart() {
      var graph_data = [];
      var lable = [];
      if (this.user.length > 0) {
        for (var i in this.user) {
          graph_data.push(this.user[i].total_count);
          lable.push(this.user[i].date);
        }
      }
      if (this.myChart) {
        this.myChart.destroy();
      }
      const ctx = document.getElementById("myChart1").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: lable,
          datasets: [
            {
              label: "CUSTOMERS",
              data: graph_data,
              backgroundColor: "#87CEEB",
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    getProduct(type) {
      this.isProductLoader = true;
      this.product = "";
      var obj = {
        type: type,
        past: this.product_past,
      };
      if (type) {
        obj.type = type;
        this.product_type = type;
      } else {
        obj.type = this.product_type;
      }
      this.$api
        .getAPI({
          _action: "/graph/data/product",
          _body: obj,
        })
        .then((res) => {
          (this.product = res.result.list), (this.isProductLoader = false);
          this.getProductChart();
        })
        .catch(() => {
          this.isProductLoader = false;
        });
    },

    getProductChart() {
      var product_data = [];
      var lable = [];
      if (this.product.length > 0) {
        for (var i in this.product) {
          product_data.push(this.product[i].total_count);
          lable.push(this.product[i].date);
        }
      }
      if (this.productChart) {
        this.productChart.destroy();
      }
      const ctx = document.getElementById("myChart2").getContext("2d");
      this.productChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: lable,
          datasets: [
            {
              label: "PRODUCT",
              data: product_data,
              backgroundColor: "#FFD700",
              borderColor: "#FFD700",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    getBrand(type) {
      this.isBrandLoader = true;
      this.brand = "";
      var obj = {
        type: type,
        past: this.brand_past,
      };
      if (type) {
        obj.type = type;
        this.brand_type = type;
      } else {
        obj.type = this.brand_type;
      }
      this.$api
        .getAPI({
          _action: "/graph/data/brand",
          _body: obj,
        })
        .then((res) => {
          (this.brand = res.result.list), (this.isBrandLoader = false);
          this.getBrandChart();
        })
        .catch(() => {
          this.isBrandLoader = false;
        });
    },

    getBrandChart() {
      var brand_data = [];
      var lable = [];
      if (this.brand.length > 0) {
        for (var i in this.brand) {
          brand_data.push(this.brand[i].total_count);
          lable.push(this.brand[i].date);
        }
      }
      if (this.brandChart) {
        this.brandChart.destroy();
      }
      const ctx = document.getElementById("myChart3").getContext("2d");
      this.brandChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: lable,
          datasets: [
            {
              label: "BRAND",
              data: brand_data,
              backgroundColor: "#9400D3",
              borderColor: "#9400D3",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    getReview(type) {
      this.isReviewLoader = true;
      this.review = "";
      var obj = {
        type: type,
        past: this.review_past,
      };
      if (type) {
        obj.type = type;
        this.review_type = type;
      } else {
        obj.type = this.review_type;
      }
      this.$api
        .getAPI({
          _action: "/graph/data/review",
          _body: obj,
        })
        .then((res) => {
          (this.review = res.result.list), (this.isReviewLoader = false);
          this.getReviewChart();
        })
        .catch(() => {
          this.isReviewLoader = false;
        });
    },

    getReviewChart() {
      var review_data = [];
      var lable = [];
      if (this.review.length > 0) {
        for (var i in this.review) {
          review_data.push(this.review[i].total_count);
          lable.push(this.review[i].date);
        }
      }
      if (this.reviewChart) {
        this.reviewChart.destroy();
      }
      const ctx = document.getElementById("myChart4").getContext("2d");
      this.reviewChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: lable,
          datasets: [
            {
              label: "REVIEW",
              data: review_data,
              backgroundColor: "#59981a",
              borderColor: "#59981a",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    getCountryList() {
      this.$api
        .getAPI({
          _action: "/country/list",
        })
        .then((res) => {
          this.countryList = res;
          if (this.detail.country_id != null) {
            this.country_id = this.detail.country_id;
          }
        })
        .catch(() => {});
    },
    getTopBrandList() {
      // this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      if (this.country_id) {
        this.detail.country_id = this.country_id;
      }
      var obj = {};
      obj.country_id = this.country_id;
      this.$api
        .getAPI({
          _action: "/top/brands",
          _body: obj,
        })
        .then((res) => {
          // this.loader = false;
          this.list = res;
          // if (this.$refs.errorComponent) {
          //   this.$refs.errorComponent.setData(res, "No best brand  available");
          //   this.$refs.errorComponent.updateLoader(false);
          // }
        })
        .catch(() => {
          // this.loader = false;
          // if (this.$refs.errorComponent) {
          //   this.$refs.errorComponent.setData(0, undefined, "");
          //   this.$refs.errorComponent.updateLoader(false);
          // }
        });
    },
    getTopProductList() {
      this.productList = [];
      if (this.country_id) {
        this.detail.country_id = this.product_country_id;
      }
      var obj = {};
      obj.country_id = this.detail.product_country_id;
      this.$api
        .getAPI({
          _action: "/top/product",
          _body: obj,
        })
        .then((res) => {
          this.productList = res;
        })
        .catch(() => {});
    },
    getTopSharedProductList() {
      this.sharedProductList = [];
      if (this.country_id) {
        this.detail.country_id = this.shared_product_country_id;
      }
      var obj = {};
      obj.country_id = this.detail.shared_product_country_id;
      this.$api
        .getAPI({
          _action: "/top/shared/product",
          _body: obj,
        })
        .then((res) => {
          this.sharedProductList = res;
        })
        .catch(() => {});
    },
    showAddStatsPopup(type) {
      console.log("type", type);
      this.type = type;
      this.$refs.textModal.showModal();
      this.$refs.brandForm.resetForm();
    },
    downloadFile() {
      var url = "/shared/product/excel/file";
      if (this.type == "brand") {
        url = "/brand/excel/file";
      } else if (this.type == "product") {
        url = "/product/excel/file";
      }
      url +=
        "?parameter=" + this.detail.parameter + "&country_id=" + this.detail.country_id;
      if (this.detail.parameter == "DAILY") {
        url +=
          "&from_date=" +
          moment(this.detail.from_date).format("YYYY-MM-DD") +
          "&to_date=" +
          moment(this.detail.to_date).format("YYYY-MM-DD");
      } else if (this.detail.parameter == "MONTHLY") {
        url +=
          "&month=" +
          moment(this.detail.month).format("MM") +
          "&year=" +
          moment(this.detail.year).format("YYYY");
      } else {
        url += "&year=" + moment(this.detail.year).format("YYYY");
      }
      this.$refs.textModal.closeModal();
      window.open(Report.REPORT_URL + "/api" + url);
    },
  },
};
</script>

